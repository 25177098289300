import React, { useEffect, useState } from "react";
import {
  Table,
  Dropdown,
  Pagination,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { AddParticipants, EditParticipants } from "../index";
import {
  BsXLg,
  BsXCircle,
  BsCaretDownFill,
  BsCaretUpFill,
  BsFillCheckCircleFill,
  BsQuestionCircle,
} from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useGetParticipant, useRemoveParticipant, useGetTeams } from "@/customHooks/index";
import { decrypt, encrypt } from "@/utils/crypto";
import { useFetchTeamParticipant } from "@/customHooks/useTeam";
import { usePatchParticipantData } from "@/customHooks/useParticipants";
import { ParticipantsTableLoader } from "@/components/loder";
import { supabase } from "@/config/supabase";
import { table } from "@/utils/constant";
import { modal } from "../../utils/constant";
import { FaBell } from 'react-icons/fa';

function ParticipantsTable({ buttonDisable, sessionData }) {
  const removeParticipant = useRemoveParticipant();
  const navigate = useNavigate();
  const [participants, setParticipants] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const params = useParams();
  const { isParticipantDataUpdate, setIsParticipantDataUpdate } =
    usePatchParticipantData();
  const { data, refetch, isLoading } = useGetParticipant(
    Number(decrypt(params?.id)),
  );
  const getTeam = useGetTeams(Number(decrypt(params?.id)));
  const getTeamParticipant = useFetchTeamParticipant(
    Number(decrypt(params?.id)),
  );
  const [show, setShow] = useState(false);
  const [editData, setEditData] = useState();
  const [editModal, setEditModal] = useState(false);
  const [tableColumnArray, setTableColumnArray] = useState([]);
  const maxParticipants = 10;
  const participantsCount = participants?.length || 0;
  const sessionParticipants = sessionData?.session?.no_of_participant || 0;
  const remainingParticipants = Math.max(
    0,
    totalPages !== currentPage || sessionParticipants % maxParticipants === 0
      ? maxParticipants - participantsCount
      : (sessionParticipants % maxParticipants) - participantsCount,
  );

  const remainingSessionParticipants = Math.max(
    0,
    sessionParticipants <= maxParticipants
      ? !participantsCount
        ? sessionParticipants
        : sessionParticipants - participantsCount
      : totalPages === currentPage
        ? !participantsCount
          ? sessionParticipants % maxParticipants || maxParticipants
          : maxParticipants - participantsCount
        : maxParticipants,
  );

  const additionalRowsLength = Math.min(
    remainingParticipants,
    remainingSessionParticipants,
  );

  const handleShow = () => {
    setShow(!show);
  };
  const editModalShow = (data) => {
    setEditData(data);
    setEditModal(!editModal);
  };

  const handleDelete = async (item) => {
    await removeParticipant.mutateAsync(item);
    refetch();
    getTeamParticipant.refetch();
    setIsParticipantDataUpdate(true);
  };
  const handleClick = () => {
    navigate(`/teams/${encrypt(String(sessionData?.session?.id))}`);
  };

  useEffect(() => {
    buttonDisable(data?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.length]);

  useEffect(() => {
    if (editModal === false) {
      if (typeof refetch === 'function') {
        refetch();
      }
      handlePagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editModal]);

  const handleToggleState = (index, text) => {
    let temp = tableColumnArray;
    temp = tableColumnArray.map((val, ind) => {
      val.selected = ind === index ? !val.selected : false;
      return val;
    });
    setTableColumnArray(temp);
    if (participants?.length !== 0) {
      handlePagination(currentPage, index, text);
    }
  };

  useEffect(() => {
    setIsParticipantDataUpdate(true);
  }, [data?.length]);

  useEffect(() => {
		const fetchData = async () => {
			setTableColumnArray([
				{ index: 0, text: 'Name', selected: false },
				{ index: 1, text: 'Assigned Team', selected: false },
				{ index: 2, text: 'Assigned Role', selected: false },
				{ index: 3, text: 'Private note', selected: false },
				// { index: 3, text: "Email", selected: false },
			]);

			const newTotalPages = Math.ceil(
				sessionData?.session?.no_of_participant /
					process.env.REACT_APP_PARTICIPANTS_TABLE_PAGE
			);

			if (currentPage > newTotalPages) {
				setCurrentPage(1);
				await handlePagination(1, 0);
			} else {
				await handlePagination(currentPage, 0);
			}
			setIsParticipantDataUpdate(false);
		};
		fetchData();
		getTeam.refetch();
		getTeamParticipant.refetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isParticipantDataUpdate]);

  const handlePagination = async (current, index, val) => {
    let id = Number(decrypt(params?.id));
    let pageSize = 10;
    let currentPage = current || 1;
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    if (!id) return;
    let { data, error } = await supabase
      .from("participant")
      .select(
        "*, team_participant (*, role_id(id, name), team_id(id, name, team_index))",
      )
      .eq("session_id", id)
      .order(index === 1 ? "email" : "first_name", {
        ascending: val ? !val?.selected : true,
      });
    const page_number = data?.length >= 10 ? currentPage : 1;
    setCurrentPage(page_number);
    if (index === 1) {
      data?.sort((a, b) => {
        const teamNameA = a.team_participant[0]?.team_id.name || "";
        const teamNameB = b.team_participant[0]?.team_id.name || "";
        return !val.selected
          ? teamNameA.localeCompare(teamNameB)
          : teamNameB.localeCompare(teamNameA);
      });
      setParticipants(data?.slice(startIndex, endIndex));
    } else if (index === 2) {
      data?.sort((a, b) => {
        const roleNameA = a.team_participant[0]?.role_id.name || "";
        const roleNameB = b.team_participant[0]?.role_id.name || "";
        return !val.selected
          ? roleNameA.localeCompare(roleNameB)
          : roleNameB.localeCompare(roleNameA);
      });
      setParticipants(data?.slice(startIndex, endIndex));
    }

    if (error) {
      console.error("Error fetching participant data:", error.message);
    }
    setParticipants(data?.slice(startIndex, endIndex));
  };
  const handlePageChange = async (newPage) => {
    if (newPage <= totalPages && newPage >= 1) {
      const elements = tableColumnArray.find((data) => data?.selected);
      if (elements) {
        await handlePagination(newPage, elements.index, elements);
      } else {
        await handlePagination(newPage, 0);
      }
      setCurrentPage(newPage);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    const maxPagesToShow = 3;
    const ellipsis = <Pagination.Ellipsis />;
    const keyPrefix = "page";
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = startPage + maxPagesToShow - 1;
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }
    if (startPage > 1) {
      pages.push(
        React.cloneElement(ellipsis, { key: `${keyPrefix}_ellipsis_start` }),
      );
    }
    for (let page = startPage; page <= endPage; page++) {
      pages.push(
        <Pagination.Item
          key={`${keyPrefix}_${page}`}
          active={page === currentPage}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Pagination.Item>,
      );
    }

    if (endPage < totalPages) {
      pages.push(
        React.cloneElement(ellipsis, { key: `${keyPrefix}_ellipsis_end` }),
      );
    }
    return pages;
  };

  useEffect(() => {
    const fetchData = async () => {
      setTotalPages(
        Math.ceil(
          sessionData?.session?.no_of_participant /
            process.env.REACT_APP_PARTICIPANTS_TABLE_PAGE,
        ),
      );
    };
    fetchData();
  }, [sessionData]);

  return (
    <>
      {isLoading ? (
        <ParticipantsTableLoader />
      ) : (
        <>
          <div className="table-responsive" style={{ overflow: "unset" }}>
            <Table style={{ tableLayout: "fixed" }} className="mb-10" bordered>
              <thead>
                <tr>
                  {tableColumnArray?.map((item, index) => {
                    return (
                      <th key={index}>
                        {index !== 3 ? (
                            <div onClick={() => handleToggleState(index, item)}>
                              {item?.text}
                              {item?.selected ? (
                                <BsCaretDownFill />
                              ) : (
                                <BsCaretUpFill />
                              )}
                            </div>
                        ) : (
                            <div onClick={() => handleToggleState(index, item)}>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip
                                    id="button-tooltip-2"
                                    className="tooltip-light"
                                    style={{
                                      maxWidth: "280px !important",
                                      padding: "10px 20px",
                                    }}
                                  >
                                    <p>{modal?.privateNoteTooltip}</p>
                                  </Tooltip>
                                }
                              >
                                {({ ref, ...triggerHandler }) => (
                                  <div
                                    {...triggerHandler}
                                    className="d-inline-flex align-items-center"
                                  >
                                    <span className="me-2">{item?.text}</span>
                                    <span ref={ref} className="lh-none">
                                      <BsQuestionCircle />
                                    </span>
                                  </div>
                                )}
                              </OverlayTrigger>
                              {item?.selected ? (
                                <BsCaretDownFill className="mx-1" />
                              ) : (
                                <BsCaretUpFill className="mx-1" />
                              )}
                            </div>
                        )}
                      </th>
                    );
                  })}
                  {sessionData?.session?.amount !== 0 &&
                    sessionData?.session?.payment_method === 2 && (
                      <th>{table?.payment}</th>
                    )}
                </tr>
              </thead>
              <tbody>
                {/* Render actual participant rows */}
                {participants?.map((item, index) => {
                  const team_name = item?.team_participant[0]?.team_id?.name;
                  const is_help_call = item?.team_participant[0]?.is_help_call_for_facilitator;
                  const role_name = item?.team_participant[0]?.role_id?.name;
                  return (
                    <tr key={index}>
                      <td className={is_help_call?"bg-parpal-pulse":""} >
                        <Dropdown className="arrow-drop">
                          <Dropdown.Toggle
                            variant="link"
                            className="p-0 font-base"
                          >
                            {is_help_call && (
															<FaBell
																className='text-parpal circle pulse me-2'
																style={{ height: '17px', width: '15px' }}
															/>
														)}
													  {item.first_name + ' ' + item.last_name}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => editModalShow(item)}>
                              <AiOutlineEdit className="me-2" />
                              {table?.editParticipant}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDelete(item)}>
                              <BsXLg className="me-2" />
                              {table?.removeParticipant}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td
                        className={
                          team_name === undefined
                            ? "text-danger text-underline text-red-400-hover"
                            : "text-wrap"
                        }
                        onClick={() => {
                          role_name === undefined && handleClick(item?.team_id);
                        }}
                      >
                        {/* <ParticipantSelect /> */}
                        {item?.team_participant.length > 0 ? (
                          `Team ${item?.team_participant[0]?.team_id?.team_index}:  ` +
                          team_name
                        ) : (
                          <span className={"cursor-pointer"}>
                            {table?.assign}
                          </span>
                        )}
                      </td>
                      <td
                        className={
                          role_name !== undefined
                            ? ""
                            : "text-danger text-underline text-red-400-hover"
                        }
                        onClick={() =>
                          role_name === undefined && handleClick(item?.role_id)
                        }
                      >
                        {item?.team_participant.length > 0 ? (
                          role_name
                        ) : (
                          <span className={"cursor-pointer"}>
                            {table?.assign}
                          </span>
                        )}
                      </td>
                      <td>
                        <p
                          className="text-truncate"
                          data-toggle="tooltip"
                          title={item.private_note}
                        >
                          {item.private_note}
                        </p>
                      </td>
                      {sessionData?.session?.payment_method === 2 &&
                      sessionData?.session?.amount !== 0 ? (
                        item.is_paid === 1 ? (
                          <td className="text-success">
                            <BsFillCheckCircleFill className="me-2" />
                            {table?.paid}
                          </td>
                        ) : (
                          <td className="text-danger">
                            <BsXCircle className="me-2" />
                            {table?.unPaid}
                          </td>
                        )
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                })}
                {Array.from({
                  length: additionalRowsLength,
                }).map((_, index) => (
                  <tr key={index}>
                    <td
                      className="text-danger cursor-pointer text-underline text-red-400-hover"
                      onClick={handleShow}
                    >
                      + {table?.addNew}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    {sessionData?.session?.amount !== 0 &&
                      sessionData.session?.payment_method === 2 && <td></td>}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <Pagination className="mb-8">
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
            />
            {renderPageNumbers()}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </Pagination>
          {show && (
            <AddParticipants
              show={show}
              hide={() => handleShow()}
              refetch={refetch}
            />
          )}
          {editModal && (
            <EditParticipants
              show={editModal}
              hide={() => editModalShow()}
              editData={editData}
              refetch={refetch}
            />
          )}
        </>
      )}
    </>
  );
}
export default ParticipantsTable;
