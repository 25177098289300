import React, { useState, useEffect } from "react";
import moment from "moment";
import { supabase } from '@/config/supabase';

const CountdownTimer = (props) => {
  const { time, complete, timeToShow, teamData, sessionData } = props;
  const [minutes, setMinutes] = useState(60);
  const [seconds, setSeconds] = useState(0);

  // const staticDate = moment(new Date(time).toISOString()).add(
  //   teamData?.total_pause_time, // sessionData?.total_pause_time,
  //   "seconds"
  // );
  const staticDate = moment
    .utc(time)
    .add(teamData?.total_pause_time, "seconds");
  const pause_date_time = teamData?.pause_date_time; // sessionData?.pause_date_time;

  // Check session date is today date then time is working
  const sessionDate = moment(sessionData?.session_date);
  const currentDate = moment();
  const isToday = sessionDate.isSame(currentDate, "day");
  //

  const terminateService = async () => {
    if(sessionData?.orientation_video_start){
      console.log("if 1");
      await supabase
        .from('team')
        .update({
          current_status: 4,
        })
        .match({
          session_id: Number(sessionData.id),
        });
      await supabase
        .from('team_participant')
        .update({
          current_status: 4,
        })
        .match({
          session_id: Number(sessionData.id),
        });
    }
    if(sessionData?.is_game_complete === 0 && teamData?.current_status >= 5 && sessionData?.orientation_video_start){
      console.log("if 2");
      await supabase
        .from('team')
        .update({
          is_game_complete: 1,
          game_complete_status: teamData.current_round !== 24 ? 'times-complete' : 'rounds-complete'
        })
        .match({
          session_id: Number(sessionData.id),
        });
      await supabase
        .from('session')
        .update({
          is_game_complete: 1,
        })
        .match({
          id: Number(sessionData.id),
        });
    }
  };

  useEffect(() => {
    let timerInterval;
    const updateTimer = () => {
      if (complete) {
        clearInterval(timerInterval);
        setMinutes(0);
        setSeconds(0);
        return;
      } else if (!complete && time) {
        // const total = Math.max(
        //   0,
        //   timeToShow - Math.floor((new Date() - staticDate) / 1000)
        // );
        // const minutesLeft = Math.floor(total / 60);
        // const secondsLeft = total % 60;
        const now = moment();
        const total = Math.max(
          0,
          timeToShow - moment.duration(now.diff(staticDate)).asSeconds(),
        );
        const minutesLeft = Math.floor(total / 60);
        const secondsLeft = Math.floor(total % 60);
        setMinutes(minutesLeft);
        setSeconds(secondsLeft);
        if (total === 0) {
          clearInterval(timerInterval);
          terminateService();
        }
      }
    };
    if (pause_date_time) {
      const total = Math.max(
        0,
        timeToShow -
          moment.duration(moment(pause_date_time).diff(staticDate)).asSeconds(),
      );
      const minutesLeft = Math.floor(total / 60);
      const secondsLeft = Math.floor(total % 60);

      // const total = Math.max(
      //   0,
      //   timeToShow - Math.floor((new Date(pause_date_time) - staticDate) / 1000)
      // );
      // const minutesLeft = Math.floor(total / 60);
      // const secondsLeft = total % 60;
      setMinutes(minutesLeft);
      setSeconds(secondsLeft);
    } else if (isToday) {
      updateTimer();
      timerInterval = setInterval(updateTimer, 1000);
    }
    return () => {
      clearInterval(timerInterval);
    };
  }, [complete, pause_date_time, staticDate, time, timeToShow]);

  return (
    <div>
      <p>
        {timeToShow === 605 ? " (" : ""}
        {`${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
          2,
          "0",
        )}`}
        {timeToShow === 605 ? ") " : ""}
      </p>
    </div>
  );
};

export default CountdownTimer;
