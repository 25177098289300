import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
	BsFillCheckCircleFill,
	BsPlus,
	BsXLg,
	BsXCircle,
} from 'react-icons/bs';
import { MdAccessTimeFilled } from 'react-icons/md';
import { FiRefreshCw } from 'react-icons/fi';
import { FaBell } from 'react-icons/fa';
import { IoMdPower } from 'react-icons/io';
import Select from 'react-select';
import { AddParticipants, RemovePlayerModal, RefreshConnection } from './index';
import {
	useGetParticipant,
	useGetTeams,
	useRemoveTeamParticipant,
	useUpdateAddParticipate,
	useUpdateGameParticipant,
	useFetchTeamParticipant,
	// useRemoveCoRoles,
	useGetSingleSessions,
	useParticipantTeamData,
} from '@/customHooks/index';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { supabase } from '@/config/supabase';
import { decrypt } from '@/utils/crypto';
import { usePatchParticipantData } from '@/customHooks/useParticipants';
import { constant, table } from '@/utils/constant';

function ParticipantsSelect({
	teamData,
	unassigned,
	role,
	state,
	teamIndex,
	socket,
}) {
	const [modify, setModify] = useState(false);
	const updateMutation = useUpdateAddParticipate();
	const gameUpdate = useUpdateGameParticipant();
	// const removeCoRoles = useRemoveCoRoles();
	const [isOpen, setIsOpen] = useState(false);
	const [roleId, setRoleId] = useState();
	const { setIsParticipantDataUpdate } = usePatchParticipantData();
	const [value, setValue] = useState(undefined);
	const [isAddParticipantAssign, setIsAddParticipantAssign] = useState();
	const [removeParticipant, setRemoveParticipant] = useState(false);
	const [openRefreshconnectionModel, setOpenRefreshconnectionModel] =
		useState(false);
	const [showModal, setShowModal] = useState(false);
	const removeTeamParticipant = useRemoveTeamParticipant();
	const { id } = useParams();
	const getTeamParticipant = useFetchTeamParticipant(Number(decrypt(id)));
	const getTeam = useGetTeams(Number(decrypt(id)));
	const getSession = useGetSingleSessions(Number(decrypt(id)));
	const { data: participantData, refetch: refetchParticipantData } =
		useGetParticipant(Number(decrypt(id)));
	const { data: teamParticipantData, refetch: refetchTeamParticipantData } =
		useParticipantTeamData(Number(decrypt(id)), teamData.id);
	const dropdownRef = useRef(null);
	const checkStatus = getTeam?.data?.filter(item => item.current_status > 2);
	const toggleOpen = () => {
		setIsOpen(!isOpen);
		setRoleId(role?.role_id);
	};

	const onSelectChange = async value => {
		setIsOpen(true);
		setValue(value);
		let newValue = {
			participant_id: value?.value,
			role_id: roleId,
			team_id: value?.team_id,
			session_id: value?.session_id,
			current_status: 0,
			is_game_leave: false,
			unassigned_participant_id: null,
		};

		try {
			await updateMutation.mutateAsync(newValue);
			if (teamData?.current_status > 0) {
				// Refetch team participant data
				refetchTeamParticipantData();
				const filteredParticipantDataNotNull = teamParticipantData
					.filter(
						item => item.participant_id !== null && item.current_status === 5
					)
					.map(item => item.participant_id);

				const newValue = {
					participant_id: value?.value,
					role_id: roleId,
					team_id: teamData?.id,
					session_id: teamData?.session?.id,
					round_no: teamData?.current_round,
					participant_id_array: filteredParticipantDataNotNull,
				};
				await gameUpdate.mutateAsync(newValue);
			}
			getTeam.refetch();
			refetchParticipantData();
			getTeamParticipant.refetch();
			setIsParticipantDataUpdate(true);
			setIsOpen(false);
		} catch (error) {}
	};

	const showModalDialog = role => {
		setShowModal(true);
		setIsAddParticipantAssign(role);
	};

	const hideModalDialog = () => {
		setShowModal(false);
	};

	const options = participantData
		?.map(item => {
			const commonOptions = {
				value: item.id,
				label: `${item.first_name} ${item.last_name}`,
				team_id: teamData?.id,
				session_id: teamData?.session?.id,
				current_status: teamData?.current_status,
				assign: !item?.team_participant?.length ? undefined : false,
			};

			return !item?.team_participant?.length ? commonOptions : null;
		})
		.filter(Boolean);

	const modifiedOptions = (participantData || []).map(item => {
		const teamName = item.team_participant?.[0]?.team_id?.name
			? item.team_participant?.[0]?.team_id?.name.length > 25
				? `${item.team_participant?.[0]?.team_id?.name.slice(0, 25)}...`
				: item.team_participant?.[0]?.team_id?.name
			: `Team ${item.team_participant?.[0]?.team_id?.team_index}`;
		return {
			value: item.id,
			label: `${item.first_name} ${item.last_name}  ${
				item.team_participant?.length ? `(${teamName})` : ''
			}`,
			team_id: teamData?.id,
			session_id: teamData?.session?.id,
			assign: item.team_participant?.length ? false : undefined,
		};
	});

	//Custom Option Filter
	const customFilter = (option, inputValue) => {
		const optionLabelLower = option.label.toLowerCase();
		const inputValueLower = inputValue.toLowerCase();

		return optionLabelLower.includes(inputValueLower);
	};

	// Option Disabled Status
	const isOptionDisabled = option => option?.assign === false;

	// Handle Team Participant Removal
	const handleRemove = async item => {
		try {
			await removeTeamParticipant.mutateAsync({
				id: item?.id,
				participant_id: item?.participant_id?.id,
			});

			// Refetch participant data
			await refetchParticipantData();

			const filteredParticipantIds = participantData
				.filter(participant => participant.id !== null)
				.map(participant => participant.id);

			// await supabase
			// 	.from('team_participant')
			// 	.update({ is_game_leave: false })
			// 	.match({
			// 		session_id: Number(item.session_id),
			// 		team_id: Number(teamData?.id),
			// 		role_id: Number(item.role_id),
			// 	});
			if (teamData?.current_status > 0) {
				const newValue = {
					participant_id: null,
					role_id: item.role_id,
					team_id: teamData?.id,
					session_id: teamData?.session?.id,
					round_no: teamData?.current_round,
					unassigned_participant_id: item?.participant_id?.id,
					unassigned_team_id: teamData?.id,
					participant_id_array: filteredParticipantIds,
				};
				await gameUpdate.mutateAsync(newValue);
			}

			getTeam.refetch();
			getTeamParticipant.refetch();
			setIsParticipantDataUpdate(true);
			setIsOpen(false);
			setRemoveParticipant(false);
		} catch (error) {
			console.error('Error in handleRemove:', error);
		}
	};

	const handleResetGameLink = async item => {
		await supabase
			.from('team_participant')
			.update({ current_status: 0, is_help_call_for_facilitator: false })
			.match({ id: Number(item.id) });
	};

	//Manage Dropdown Click Outside Behavior
	useEffect(() => {
		const handleOutsideClick = event => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		if (isOpen) {
			document.addEventListener('mousedown', handleOutsideClick);
		}

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, [isOpen]);

	useEffect(() => {
		supabase
			.channel('custom-update-channel')
			.on(
				'postgres_changes',
				{ event: 'UPDATE', schema: 'public', table: 'team_participant' },
				() => {
					getTeam.refetch();
					getTeamParticipant.refetch();
					setIsParticipantDataUpdate(true);
				}
			)
			.subscribe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const haldeRefreshconnection = id => {
		socket.emit('refresh-connection-from-client', {
			refreshConnectionIds: [id],
		});
		setOpenRefreshconnectionModel(false);
	};

	const classNameList =
		getSession?.data?.session?.is_open_game_link &&
		role?.current_status >= 1 &&
		role?.participant_id !== null
			? 'cursor-pointer'
			: 'text-light-gray';

	const handleCancelHelpRequest = async item => {
		try {
			const { error } = await supabase
				.from('team_participant')
				.update({
					is_help_call_for_facilitator: false,
				})
				.eq('id', Number(item.id));

			if (!error) {
				setIsParticipantDataUpdate(true);
			}
		} catch (err) {}
	};
	const result = teamData?.participant.filter(item => item.current_status >= 2);
	return (
		<>
			<Dropdown
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				className='select'
				isIcon={role?.current_status > 0}
				isGameLeave={role?.is_game_leave}
				isRole={role?.is_help_call_for_facilitator}
				target={
					<Link
						onClick={toggleOpen}
						className={
							role.is_help_call_for_facilitator
								? 'text-parpal'
								: role?.is_game_leave || role?.participant_id === null
								? 'text-danger text-red-400-hover'
								: 'text-primary'
						}
					>
						{role?.participant_id === null
							? table?.assign
							: role?.participant_id?.first_name +
							  ' ' +
							  role?.participant_id?.last_name}
					</Link>
				}
				dropdownRef={dropdownRef}
				teamData={teamData}
				isReady={role?.current_status >= 2}
				isWaiting={teamData?.participant.length !== result.length}
			>
				{role.is_help_call_for_facilitator && (
					<span
						className={`cursor-pointer px-3 py-1 w-full hstack mt-1 text-truncate remove-text text-parpal`}
						onClick={() => {
							handleCancelHelpRequest(role);
						}}
					>
						<FaBell
							className='me-2 circle pulse mt-1'
							style={{ height: '17px', width: '15px' }}
						/>
						<span
							className='text-truncate'
							style={{ width: 'auto' }}
						>
							{' '}
							{constant?.cancelHelpRequest}
						</span>
					</span>
				)}
				<span
					onClick={() => {
						if (
							getSession?.data?.session?.is_open_game_link &&
							role?.current_status >= 1 &&
							role?.participant_id !== null
						) {
							setOpenRefreshconnectionModel(!openRefreshconnectionModel);
						}
					}}
					className={`${classNameList} px-3 py-1 w-full hstack mt-1 text-truncate remove-text`}
				>
					<FiRefreshCw
						className='me-2'
						style={{ height: '17px', width: '15px' }}
					/>
					<span
						className='text-truncate'
						style={{ width: 'auto' }}
					>
						{' '}
						{constant?.refreshConnection}
					</span>
				</span>
				<span
					onClick={() =>
						getSession?.data?.session?.is_open_game_link &&
						role?.current_status >= 1 &&
						role?.participant_id !== null
							? handleResetGameLink(role)
							: ''
					}
					className={`${classNameList} px-3 py-1 w-full hstack mt-1 text-truncate remove-text`}
				>
					<IoMdPower
						className='me-1'
						style={{ height: '19px', width: '18px' }}
					/>
					<span
						className='text-truncate'
						style={{ width: 'auto' }}
					>
						{' '}
						{constant?.resetGameLink}
					</span>
				</span>
				{unassigned !== 0 && role?.participant_id === null && (
					<span
						onClick={() => showModalDialog(role)}
						className='drop-link px-3 py-1 w-full hstack mt-1 z-1 text-truncate'
					>
						<BsPlus className='me-2' />
						{table?.addNew}
					</span>
				)}
				{role?.participant_id !== null ? (
					<span
						onClick={() => {
							if (checkStatus.length > 0) {
								setRemoveParticipant(true);
								setIsOpen(false);
							} else {
								handleRemove(role);
							}
						}}
						className={
							'cursor-pointer px-3 py-1 w-full hstack mt-1 text-truncate remove-text '
						}
					>
						<BsXLg
							className='me-1'
							style={{ height: '17px', width: '19px' }}
						/>
						<span
							className='text-truncate'
							style={{ width: 'auto' }}
						>
							{' '}
							{/* {constant?.unassign} */}
							{`Remove from ${
								teamData.name ? teamData.name : `Team ${teamData.team_index}`
							}`}
						</span>
					</span>
				) : (
					''
				)}
				{((role?.participant_id === null && role?.current_status > 3) || role?.current_status < 3) && (
					<Select
						as='select'
						options={modify || !options?.length ? modifiedOptions : options}
						filterOption={customFilter}
						onInputChange={e => {
							setModify(!!e);
						}}
						autoFocus
						isOpen={isOpen}
						backspaceRemovesValue={false}
						components={{ DropdownIndicator, IndicatorSeparator: null }}
						controlShouldRenderValue={false}
						isOptionDisabled={isOptionDisabled}
						hideSelectedOptions={false}
						isClearable={false}
						menuIsOpen
						onChange={onSelectChange}
						placeholder='Search...'
						value={value}
						classNamePrefix='react-select'
						classNames={{
							control: state =>
								state.isFocused ? 'mx-3 mt-2 shadow-none' : 'mx-3 mt-2',
						}}
						theme={theme => ({
							...theme,
							colors: {
								...theme.colors,
								primary: '#152536',
							},
						})}
						styles={{
							container: base => ({
								...base,
								minWidth: `263px`,
								width: `100%`,
								backgroundColor: '#FFF',
								borderTopLeftRadius: 0,
								borderTopRightRadius: 0,
								zIndex: '100',
							}),
							control: base => ({
								...base,
								borderColor: '#cccccc',
								'&:hover': {
									borderColor: '#cccccc',
								},
							}),
							placeholder: base => ({
								...base,
								color: '#A8B5C1',
							}),
							menu: base => ({
								...base,
								position: 'relative',
								backgroundColor: `transparent`,
								borderRadius: 8,
								borderTopLeftRadius: 0,
								borderTopRightRadius: 0,
								borderBottomLeftRadius: 8,
								borderBottomRightRadius: 8,
								marginTop: 0,
								marginBottom: 0,
								boxShadow: `none`,
							}),
							menuList: base => ({
								...base,
								maxHeight: '200px',
							}),
							option: base => ({
								...base,
								lineHeight: 1,
								padding: '6px 12px',
								backgroundColor: !state?.isSelected && 'white',
								color: !state?.isSelected && '#152536',
								'&:hover': {
									backgroundColor: '#152536',
									color: 'white',
								},
							}),
						}}
					/>
				)}
			</Dropdown>
			{showModal && (
				<AddParticipants
					isAddParticipantAssign={isAddParticipantAssign}
					teamId={teamData?.id}
					show={showModal}
					hide={hideModalDialog}
					participantData={participantData}
					getTeam={getTeam}
					current_status={teamData?.current_status}
					current_round={teamData?.current_round}
				/>
			)}
			{removeParticipant && (
				<RemovePlayerModal
					show={removeParticipant}
					hide={() => setRemoveParticipant(false)}
					handleRemove={() => handleRemove(role)}
				/>
			)}
			{openRefreshconnectionModel && (
				<RefreshConnection
					show={openRefreshconnectionModel}
					hide={() => setOpenRefreshconnectionModel(false)}
					handleRefreshConnection={() => {
						haldeRefreshconnection(role.participant_id.id);
					}}
					type='single'
				/>
			)}
		</>
	);
}

const Menu = props => {
	return (
		<div className='pb-10 position-absolute'>
			<div
				className='drop-select bg-light rounded-2 pb-2'
				style={{
					boxShadow: '0px 4px 4px 0px #00000040',
					border: '1px solid #CCCCCC',
					// position: "absolute",
					maxWidt: '263px',
				}}
				{...props}
			/>
		</div>
	);
};
const renderTooltip = props => (
	<Tooltip
		id='button-tooltip'
		{...props}
	>
		{table?.joined}
	</Tooltip>
);
const renderTooltipFirst = props => (
	<Tooltip
		id='button-tooltip'
		{...props}
	>
		{table?.notReady}
	</Tooltip>
);
// const renderTooltipSecond = props => (
// 	<Tooltip
// 		id='button-tooltip'
// 		{...props}
// 	>
// 		{table?.ready}
// 	</Tooltip>
// );
const renderTooltipLeave = props => (
	<Tooltip
		id='button-tooltip'
		{...props}
	>
		{constant?.leftGame}
	</Tooltip>
);
const Blanket = props => <div {...props} />;
const Dropdown = ({
	children,
	isOpen,
	target,
	onClose,
	dropdownRef,
	isIcon,
	isRole,
	isGameLeave,
	teamData,
	isReady,
	isWaiting,
}) => (
	<div
		className=''
		ref={dropdownRef}
	>
		{isGameLeave && isIcon ? (
			<OverlayTrigger
				placement='bottom'
				delay={{ show: 250, hide: 400 }}
				overlay={isGameLeave ? renderTooltipLeave : renderTooltip}
			>
				<span className='pe-2 lh-none'>
					<BsXCircle
						className='text-danger'
						size={18}
						style={{ marginTop: '-2px' }}
					/>
				</span>
			</OverlayTrigger>
		) : isIcon ? (
			<OverlayTrigger
				placement='bottom'
				delay={{ show: 250, hide: 400 }}
				overlay={renderTooltip}
			>
				<span className='pe-2 lh-none'>
					<BsFillCheckCircleFill
						className='text-success'
						size={18}
					/>
				</span>
			</OverlayTrigger>
		) : null}
		{!isGameLeave && isWaiting ? (
			<OverlayTrigger
				placement='bottom'
				delay={{ show: 250, hide: 400 }}
				overlay={renderTooltipFirst}
			>
				<span className='pe-2 lh-none'>
					<MdAccessTimeFilled
						style={{ color: '#999999' }}
						size={22}
					/>
				</span>
			</OverlayTrigger>
		// ) : !isGameLeave && isReady && !isRole ? (
		// 	<OverlayTrigger
		// 		placement='bottom'
		// 		delay={{ show: 250, hide: 400 }}
		// 		overlay={renderTooltipSecond}
		// 	>
		// 		<span className='pe-2 lh-none'>
		// 			<BsFillCheckCircleFill
		// 				className='text-success'
		// 				size={18}
		// 			/>
		// 		</span>
		// 	</OverlayTrigger>
		) : (
			isRole && (
				<FaBell
					className='text-parpal circle pulse me-2'
					style={{ height: '17px', width: '15px' }}
				/>
			)
		)}
		{target}
		{isOpen ? <Menu>{children}</Menu> : null}
		{isOpen ? <Blanket onClick={onClose} /> : null}
	</div>
);
const Svg = p => (
	<svg
		width='0'
		height='0'
		viewBox='0 0 24 24'
		focusable='false'
		role='presentation'
		{...p}
	/>
);
const DropdownIndicator = () => (
	<div>
		<Svg>
			<path
				d='M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z'
				fill='currentColor'
				fillRule='evenodd'
			/>
		</Svg>
	</div>
);
export default ParticipantsSelect;
