import { React, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Tooltip, OverlayTrigger, FormControl } from "react-bootstrap";
import { useFormik } from "formik";
import { BsXCircle, BsPlusCircle } from "react-icons/bs";
import { JoinModal, ParticipantSelect } from "../index";
import {
  useGetRoles,
  useUpdateTeam,
  useCoManagerData,
  useGetParticipant,
  useRemoveCoRoles,
  useFetchTeamParticipant,
} from "@/customHooks/index";
import { useParams } from "react-router-dom";
import { supabase } from "@/config/supabase";
import { decrypt } from "@/utils/crypto";
import { usePatchParticipantData } from "@/customHooks/useParticipants";
import { constant } from "@/utils/constant";
import { AddCoRoleLoader } from "../loder";
import { table } from "../../utils/constant";

function TeamTable({ teamData, refetch, participantData, sessionData, socket }) {
  const updateMutation = useUpdateTeam();
  const params = useParams();
  const addCoManagerData = useCoManagerData();
  const removeCoRoles = useRemoveCoRoles();
  const getParticipantData = useGetParticipant(Number(decrypt(params?.id)));
  const getTeamParticipant = useFetchTeamParticipant(
    Number(decrypt(params?.id)),
  );
  const { data: roleList } = useGetRoles();
  const { setIsParticipantDataUpdate } = usePatchParticipantData();
  const [selectedValues, setSelectedValues] = useState();
  const [show, setShow] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [isCoRoleLoading, setCoRoleLoading] = useState(false);
  const [openStates, setOpenStates] = useState(
    Array(teamData?.participant?.length).fill(false),
  );
  const dropdownRef = useRef(null);
  const [facilitatorData, setFacilitatorData] = useState();
  const filterExtraRolePart = teamData?.participant?.filter(
    (element) => element?.role_id > 4,
  );
  const getTeamParticipants = getTeamParticipant?.data?.filter((item) => {
    return item.participant_id !== null;
  });
  const unassigned =
    sessionData?.session?.no_of_participant - participantData?.data?.length;
  const disablePlus =
    getTeamParticipants?.length !== sessionData?.session?.no_of_participant
      ? sessionData?.session?.no_of_participant % 4
      : 0;

  const handleShow = (item) => {
    setShow(!show);
    setFacilitatorData(item);
  };
  //Implement Scroll Behavior
  useEffect(() => {
    document.addEventListener("click", function (event) {
      var screnY = event.clientY;
      if (screnY > 700) {
        window.scrollTo(0, window.scrollY + 190);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSelect]);

  const addModalShow = () => {
    if (disablePlus !== 0) {
      setShowSelect(!showSelect);
      setSelectedValues(teamData?.total_role + 1);
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Not available yet
    </Tooltip>
  );

  const formik = useFormik({
    initialValues: {
      team: teamData?.name,
      team_index: teamData?.team_index,
    },
    onSubmit: async (values) => {
      if (values?.team !== teamData?.name) {
        let newValue = {
          id: teamData?.id,
          name: values?.team,
        };
        try {
          await updateMutation.mutateAsync(newValue);
          refetch();
          setIsParticipantDataUpdate(true);
          getParticipantData.refetch();
        } catch (error) {}
      }
    },
  });
  const handleState = (index) => {
    const updatedOpenStates = openStates.map((state, ind) => {
      if (ind === index) {
        return !state;
      } else {
        return false;
      }
    });
    setOpenStates(updatedOpenStates);
  };
  const handleSelectChange = async (e) => {
    try {
      setCoRoleLoading(true);
      const isFilterExtraRolePartExist = filterExtraRolePart.filter(
        (element) => element?.role_id === Number(e.target.value),
      );
      if (isFilterExtraRolePartExist.length > 0 && e.target.value > 4) {
        let newValue = {
          role_id: e.target.value,
          team_id: teamData?.id,
          session_id: sessionData?.session?.id,
        };
        await removeCoRoles.mutateAsync(newValue);
        await refetch();
        await setIsParticipantDataUpdate(true);
        await participantData.refetch();
      } else if (e.target.value) {
        let newValue = {
          role_id: e.target.value,
          team_id: teamData?.id,
          session_id: sessionData?.session?.id,
        };
        await addCoManagerData.mutateAsync(newValue);
        await refetch();
        await participantData.refetch();
      }
      setCoRoleLoading(false);
    } catch (error) {
      setCoRoleLoading(false);
    }
  };
  const removeCoRoleDisplay = (item) => {
    const isFilterExtraRolePartExist = filterExtraRolePart?.filter(
      (element) => element?.role_id === Number(item?.id),
    );
    if (isFilterExtraRolePartExist.length > 0) {
      return "Remove " + item?.name;
    } else if (item?.id === 5) {
      return `${item.name} (Recommended)`;
    } else {
      return item?.name;
    }
  };

  useEffect(() => {
    if (typeof refetch !== 'function') {
      return;
    }
    const channel = supabase
      .channel("custom-update-channel")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "team" },
        (payload) => {
          if (typeof refetch === 'function') {
            refetch();
          }
        },
      )
      .subscribe();
    return () => {
      channel.unsubscribe();
    };
  }, [refetch]);

  useEffect(() => {
    formik.setFieldValue("team", teamData?.name);
    formik.setFieldValue("team_index", teamData?.team_index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamData, refetch]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowSelect(false);
      }
    };

    if (showSelect) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSelect]);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="input-group  mb-3 team-input-sec">
          <label className="input-group-text border-0" htmlFor="firstName">
            Team {formik.values.team_index} :
          </label>
          <FormControl
            onBlur={() => formik.handleSubmit()}
            className="border-0 overlap-10"
            id=""
            value={formik.values.team}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                formik.handleSubmit();
                event?.target?.blur();
              }
            }}
            onChange={formik.handleChange}
            name="team"
            placeholder="Add hospital name..."
          />
        </div>
      </form>
      {isCoRoleLoading ? (
        <AddCoRoleLoader row={teamData?.participant.length} />
      ) : (
        <Table className="mb-3" responsive bordered>
          <thead>
            <tr>
              <th>{constant?.role}</th>
              <th>{constant?.participant}</th>
              <th>{constant?.gameLink}</th>
            </tr>
          </thead>
          <tbody>
            {teamData?.participant?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    {roleList?.roles?.find((role) => role.id === item.role_id)
                      ?.name || ""}
                  </td>
                  <td className={item.is_help_call_for_facilitator?"bg-parpal-pulse":""} onClick={() => handleState(index)}>
                    <ParticipantSelect
                      state={openStates[index]}
                      teamData={teamData}
                      refetch={refetch}
                      unassigned={unassigned}
                      role={item}
                      teamIndex={formik.values.team_index}
                      socket={socket}
                    />
                  </td>
                  <td>
                    {sessionData?.session?.is_open_game_link && !!item?.participant_id? (
                      <Link
                        className="text-blue2"
                        onClick={() => handleShow(item)}
                      >
                        {table?.joinAsFacilitator}
                      </Link>
                    ) : (
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <span>
                          <BsXCircle />
                        </span>
                      </OverlayTrigger>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      <div ref={dropdownRef} className="w-max">
        <BsPlusCircle
          onClick={addModalShow}
          className="text-lg"
          style={{ color: disablePlus === 0 ? "#8898A9" : "" }}
        />
        {showSelect && (
          <select
            className="form-select input-select rounded-2 px-0 w-max"
            multiple
            aria-label="multiple select example"
            onChange={(e) => {
              handleSelectChange(e);
              setShowSelect(false);
            }}
            value={selectedValues || []}
          >
            {roleList?.roles.slice(4, 8).map((item, index) => (
              <option className="input-option " key={index} value={item?.id}>
                {removeCoRoleDisplay(item)}
              </option>
            ))}
          </select>
        )}
      </div>

      {show && (
        <JoinModal
          show={show}
          hide={() => handleShow()}
          facilitatorData={facilitatorData}
          teamId={teamData?.id}
          facilitatorId={sessionData?.session?.facilitator?.id}
        />
      )}
      {/* {addModal && (
        <AddParticipants show={addModal} hide={() => addModalShow()} />
      )} */}
    </>
  );
}
export default TeamTable;
