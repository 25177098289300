import { useFormik } from "formik";
import React, { useEffect } from "react";
import { FormControl, Table } from "react-bootstrap";
import { BsFillCheckCircleFill, BsXCircle } from "react-icons/bs";
import {
  useGetRoles,
  useGetSingleSessions,
  useGetTeams,
  useUpdateAddParticipate,
  useUpdateGameParticipant,
  useUpdateTeam,
} from "@/customHooks";
import { supabase } from "@/config/supabase";
import { useParams } from "react-router-dom";
import { decrypt } from "@/utils/crypto";
import { constant, modal, table } from "@/utils/constant";

function GuestTeamTable({ teamData, refetch, current_status, current_round }) {
  const updateMutation = useUpdateTeam();
  const updateAddParticipateMutation = useUpdateAddParticipate();
  const { id } = useParams();
  const getSession = useGetSingleSessions(Number(decrypt(id)));
  const gameUpdate = useUpdateGameParticipant();
  const getTeam = useGetTeams(Number(decrypt(id)));
  const { data: roleList } = useGetRoles();
  const sessionData = getSession?.data || {};
  const session = sessionData?.session || {};

  const formik = useFormik({
    initialValues: {
      team: teamData?.name,
      team_index: teamData?.team_index,
    },
    onSubmit: async (values) => {
      if (values?.team !== teamData?.name) {
        let newValue = {
          id: teamData?.id,
          name: values?.team,
        };
        try {
          await updateMutation.mutateAsync(newValue);
          refetch();
        } catch (error) {}
      }
    },
  });

  const handleParticipantJoin = async (item) => {
    if (item?.current_status !== current_status || item?.is_game_leave) {
      const commonValues = {
        participant_id: item?.participant_id?.id,
        role_id: item.role_id,
        team_id: teamData?.id,
        session_id: item?.session_id,
      };

      const newValue = {
        ...commonValues,
        current_status: current_status,
        is_game_leave: false,
        handle_participant_id: [],
      };

      const gameUpdateValue = {
        ...commonValues,
        round_no: current_round,
      };

      await updateAddParticipateMutation.mutateAsync(newValue);
      await gameUpdate.mutateAsync(gameUpdateValue);
    } else {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      await supabase
        .from("team_participant")
        .update({ time_zone: timeZone })
        .match({
          id: Number(item.id),
          session_id: Number(item.session_id),
          role_id: Number(item.role_id),
        });
    }
    // window.open(
    //   `${process.env.REACT_APP_GAME_URL}/join?participant_id=${item.participant_id?.id}&team_id=${teamData?.id}&session_id=${item.session_id}&join_as=${item.participant_id?.id}&department_id=${item?.role_id}`,
    //   "_blank"
    // );
  };

  useEffect(() => {
    formik.setFieldValue("team", teamData?.name);
    formik.setFieldValue("team_index", teamData?.team_index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamData, refetch]);

  useEffect(() => {
    const subscribeToChanges = async () => {
      try {
        // Create a supabase channel 
        const channel = supabase.channel("custom-update-channel");
        channel.on(
          "postgres_changes",
          { event: "UPDATE", schema: "public", table: "session" },
          (data) => {
            if (data.old.id === Number(decrypt(id))) {
              if (getSession && typeof getSession.refetch === 'function') {
                getSession.refetch();
              }
            }
          }
        );
        channel.on(
          "postgres_changes",
          { event: "UPDATE", schema: "public", table: "team" },
          () => {
            if (typeof refetch === 'function') {
              refetch();
            }
          }
        );
        // Listen to team participant updates
        channel.on(
          "postgres_changes",
          { event: "UPDATE", schema: "public", table: "team_participant" },
          () => {
            if (getTeam && typeof getTeam.refetch === 'function') {
              getTeam.refetch();
            }
          }
        );

        // Subscribe to the channel
        await channel.subscribe();
        return () => {
          channel.unsubscribe();
        };
      } catch (error) {
      }
    };

    subscribeToChanges();
  }, [id, getSession, getTeam, refetch]);

  useEffect(() => {
    formik.setFieldValue("team", teamData?.name);
    formik.setFieldValue("team_index", teamData?.team_index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamData, refetch]);
  return (
    <>
      <form>
        <div className="input-group  mb-3 team-input-sec">
          <label className="input-group-text border-0" htmlFor="firstName">
            Team {formik.values.team_index} :
          </label>
          <FormControl
            onBlur={() => formik.handleSubmit()}
            className="border-0"
            id=""
            value={formik.values.team}
            onChange={formik.handleChange}
            name="team"
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                event?.target?.blur();
                formik.handleSubmit();
              }
            }}
            placeholder="Add hospital name..."
          />
        </div>
      </form>
      <Table className="mb-3" responsive bordered>
        <thead>
          <tr>
            <th>{constant?.role}</th>
            <th>{constant?.participant}</th>
            <th>{constant?.gameLink}</th>
          </tr>
        </thead>
        <tbody>
          {teamData?.participant?.map((item, index) => {
            return (
              <tr key={index}>
              	<td>
									{roleList?.roles?.find(({ id }) => id === item.role_id)
										?.name ?? ''}
								</td>
                <td
                  className={item?.participant_id === null ? "text-danger" : ""}
                >
                  <span>
                    {item?.participant_id !== null &&
                    item?.current_status > 0 ? (
                      <>
                        {!item?.is_game_leave && (
                          <BsFillCheckCircleFill className="me-2 text-success" />
                        )}
                        {item?.participant_id?.first_name +
                          " " +
                          item?.participant_id?.last_name}
                      </>
                    ) : item?.participant_id !== null ? (
                      item?.participant_id?.first_name +
                      " " +
                      item?.participant_id?.last_name
                    ) : (
                      table?.unassigned
                    )}
                  </span>
                </td>
                <td>
                  <span>
                    {session?.is_open_game_link === false ? (
                      <BsXCircle />
                    ) : item?.current_status > 0 && !item?.is_game_leave ? (
                      table?.joined
                    ) : item?.participant_id ? (
                      // <span
                      //   onClick={() => handleParticipantJoin(item)}
                      //   className="text-success text-underline cursor-pointer"
                      //   // target="_blank"
                      //   rel="noreferrer"
                      // >
                      //   {modal?.joinModalButtonText}
                      // </span>
                      <a
                        target="_blank"
                        className="text-success text-underline cursor-pointer"
                        href={`${process.env.REACT_APP_GAME_URL}/join?participant_id=${item.participant_id?.id}&team_id=${teamData?.id}&session_id=${item.session_id}&join_as=${item.participant_id?.id}&department_id=${item?.role_id}`}
                        rel="noreferrer"
                        onClick={() => handleParticipantJoin(item)}
                      >
                        {modal?.joinModalButtonText}
                      </a>
                    ) : (
                      <BsXCircle />
                    )}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default GuestTeamTable;
